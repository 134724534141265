
/*eslint-disable*/
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { Actions } from '@/store/enums/DashboardEnums.ts';
import { useStore } from 'vuex';
export default defineComponent({
  name: "dashboard",
  setup() {

    const store = useStore();

    onMounted(() => {

      store.dispatch(Actions.GET_STATS);

      store.dispatch(Actions.GET_ACTIVITIES);

      setCurrentPageTitle("Dashboard");
    });

    return {
      store
    }
  }
});
