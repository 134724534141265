<template>
<div v-if="store.getters.currentUser.role.name == 'clonallon'">
  <!--begin::Dashboard-->
  <div class="row g-5 g-xl-8">
    <div class="col-xl-3">
      <!--begin::Statistics Widget 5-->
      <a href="#" class="card bg-light-primary hoverable card-xl-stretch mb-xl-8 shadow">
        <!--begin::Body-->
        <div class="card-body">
          <!--begin::Svg Icon | path: icons/duotone/Media/Equalizer.svg-->
          <span class="svg-icon svg-icon-primary svg-icon-3x ms-n1">
            <inline-svg src="media/icons/duotone/Communication/Group.svg" />
          </span>
          <!--end::Svg Icon-->
          <div class="text-primary fw-bolder fs-2 mb-2 mt-5">{{store.getters.myStats.people ? Number(store.getters.myStats.people.consumers) : ''}}</div>
          <div class="fw-bold text-primary fs-7">Total Consumers</div>
        </div>
        <!--end::Body-->
      </a>
      <!--end::Statistics Widget 5-->
    </div>
    <div class="col-xl-3">
      <!--begin::Statistics Widget 5-->
      <a href="#" class="card bg-light-warning hoverable card-xl-stretch mb-xl-8 shadow">
        <!--begin::Body-->
        <div class="card-body">
          <!--begin::Svg Icon | path: icons/duotone/Home/Building.svg-->
          <span class="svg-icon svg-icon-warning svg-icon-3x ms-n1">
            <inline-svg src="media/icons/duotone/Shopping/Cart1.svg" />
          </span>
          <!--end::Svg Icon-->
          <div class="text-warning fw-bolder fs-2 mb-2 mt-5">{{store.getters.myStats.people ? Number(store.getters.myStats.people.suppliers) : ''}}</div>
          <div class="fw-bold text-warning fs-7">Total Suppliers</div>
        </div>
        <!--end::Body-->
      </a>
      <!--end::Statistics Widget 5-->
    </div>
    <div class="col-xl-3">
      <!--begin::Statistics Widget 5-->
      <a href="#" class="card bg-light-success hoverable card-xl-stretch mb-xl-8 shadow">
        <!--begin::Body-->
        <div class="card-body">
          <!--begin::Svg Icon | path: icons/duotone/Communication/Group.svg-->
          <span class="svg-icon svg-icon-success svg-icon-3x ms-n1">
            <inline-svg src="media/icons/duotone/General/Scissors.svg" />
          </span>
          <!--end::Svg Icon-->
          <div class="text-success fw-bolder fs-2 mb-2 mt-5">{{store.getters.myStats.stock ? Number(store.getters.myStats.stock.components) : ''}}</div>
          <div class="fw-bold text-success fs-7">Components In Stock</div>
        </div>
        <!--end::Body-->
      </a>
      <!--end::Statistics Widget 5-->
    </div>
    <div class="col-xl-3">
      <!--begin::Statistics Widget 5-->
      <a href="#" class="card bg-light-danger hoverable card-xl-stretch mb-5 mb-xl-8 shadow">
        <!--begin::Body-->
        <div class="card-body">
          <!--begin::Svg Icon | path: icons/duotone/Shopping/Chart-pie.svg-->
          <span class="svg-icon svg-icon-danger svg-icon-3x ms-n1">
            <inline-svg src="media/icons/duotone/Shopping/Box2.svg" />
          </span>
          <!--end::Svg Icon-->
          <div class="text-danger fw-bolder fs-2 mb-2 mt-5">{{store.getters.myStats.stock ? Number(store.getters.myStats.stock.composites) : ''}}</div>
          <div class="fw-bold text-danger fs-7">Composites In Stock</div>
        </div>
        <!--end::Body-->
      </a>
      <!--end::Statistics Widget 5-->
    </div>
  </div>


  <div class="row g-5 g-xl-8">

    <div class="col-xl-3">
      <!--begin::Statistics Widget 5-->
      <a href="#" class="card bg-light-success hoverable card-xl-stretch mb-xl-8 shadow">
        <!--begin::Body-->
        <div class="card-body">
          <!--begin::Svg Icon | path: icons/duotone/Communication/Group.svg-->
          <span class="svg-icon svg-icon-success svg-icon-3x ms-n1">
            <inline-svg src="media/icons/duotone/General/Scissors.svg" />
          </span>
          <!--end::Svg Icon-->
          <div class="text-success fw-bolder fs-2 mb-2 mt-5">£{{store.getters.myStats.value ? Number(store.getters.myStats.value.components).toFixed(2) : ''}}</div>
          <div class="fw-bold text-success fs-7">Total Components Value</div>
        </div>
        <!--end::Body-->
      </a>
      <!--end::Statistics Widget 5-->
    </div>
    <div class="col-xl-3">
      <!--begin::Statistics Widget 5-->
      <a href="#" class="card bg-light-danger hoverable card-xl-stretch mb-5 mb-xl-8 shadow">
        <!--begin::Body-->
        <div class="card-body">
          <!--begin::Svg Icon | path: icons/duotone/Shopping/Chart-pie.svg-->
          <span class="svg-icon svg-icon-danger svg-icon-3x ms-n1">
            <inline-svg src="media/icons/duotone/Shopping/Box2.svg" />
          </span>
          <!--end::Svg Icon-->
          <div class="text-danger fw-bolder fs-2 mb-2 mt-5">£{{store.getters.myStats.value ? Number(store.getters.myStats.value.composites).toFixed(2) : ''}}</div>
          <div class="fw-bold text-danger fs-7">Total Composites Value</div>
        </div>
        <!--end::Body-->
      </a>
      <!--end::Statistics Widget 5-->
    </div>

    <div class="col-xl-3">
      <!--begin::Statistics Widget 5-->
      <a href="#" class="card bg-light-primary hoverable card-xl-stretch mb-xl-8 shadow">
        <!--begin::Body-->
        <div class="card-body">
          <!--begin::Svg Icon | path: icons/duotone/Communication/Group.svg-->
          <span class="svg-icon svg-icon-primary svg-icon-3x ms-n1">
            <inline-svg src="media/icons/duotone/Communication/Incoming-box.svg" />
          </span>
          <!--end::Svg Icon-->
          <div class="text-primary fw-bolder fs-2 mb-2 mt-5">{{store.getters.myStats.activeOrders ? Number(store.getters.myStats.activeOrders.suppliers) : ''}}</div>
          <div class="fw-bold text-primary fs-7">Active Orders To Supplier</div>
        </div>
        <!--end::Body-->
      </a>
      <!--end::Statistics Widget 5-->
    </div>
    <div class="col-xl-3">
      <!--begin::Statistics Widget 5-->
      <a href="#" class="card bg-light-warning hoverable card-xl-stretch mb-5 mb-xl-8 shadow">
        <!--begin::Body-->
        <div class="card-body">
          <!--begin::Svg Icon | path: icons/duotone/Shopping/Chart-pie.svg-->
          <span class="svg-icon svg-icon-warning svg-icon-3x ms-n1">
            <inline-svg src="media/icons/duotone/Communication/Outgoing-box.svg" />
          </span>
          <!--end::Svg Icon-->
          <div class="text-warning fw-bolder fs-2 mb-2 mt-5">{{store.getters.myStats.activeOrders ? Number(store.getters.myStats.activeOrders.consumers) : ''}}</div>
          <div class="fw-bold text-warning fs-7">Active Orders From Consumer</div>
        </div>
        <!--end::Body-->
      </a>
      <!--end::Statistics Widget 5-->
    </div>
  </div>

  <!--begin::List Widget 5-->
  <div class="card" :class="widgetClasses">
    <!--begin::Header-->
    <div class="card-header align-items-center border-0 mt-4">
      <h3 class="card-title align-items-start flex-column">
        <span class="fw-bolder mb-2 text-dark">Activities</span>
        <!-- <span class="text-muted fw-bold fs-7">890,344 Sales</span> -->
      </h3>
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body pt-5">
      <!--begin::Timeline-->
      <div class="timeline-label">
        <!--begin::Item-->
        <div class="timeline-item" v-for="(item, key) in store.getters.myActivities" :key="key">
          <!--begin::Label-->
          <div class="timeline-label fw-bolder text-gray-800 fs-6">{{ item.dateTime }}</div>
          <!--end::Label-->

          <!--begin::Badge-->
          <div class="timeline-badge">
            <i class="fa fa-genderless fs-1" :class="item.class"></i>
          </div>
          <!--end::Badge-->

          <!--begin::Text-->
          <div class="fw-mormal timeline-content text-muted ps-3" v-html="item.text">
          </div>
          <!--end::Text-->
        </div>
        <!--end::Item-->
      </div>
      <!--end::Timeline-->
    </div>
    <!--end: Card Body-->
  </div>
  <!--end: List Widget 5-->

</div>
  <!--end::Dashboard-->
</template>

<script lang="ts">
/*eslint-disable*/
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { Actions } from '@/store/enums/DashboardEnums.ts';
import { useStore } from 'vuex';
export default defineComponent({
  name: "dashboard",
  setup() {

    const store = useStore();

    onMounted(() => {

      store.dispatch(Actions.GET_STATS);

      store.dispatch(Actions.GET_ACTIVITIES);

      setCurrentPageTitle("Dashboard");
    });

    return {
      store
    }
  }
});
</script>
<style>
  .timeline-label .timeline-label{width:150px}
  .timeline-label:before{left:135px}
</style>
